import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking } from '../../../../../../..';
import { HeaderThree } from '../../../../../../styles';
import { Row } from '../../../../../common';
import { SectionComponentProps, SummaryTextField } from '../shared';

export function AttendeesSectionEditor({ summaryJSON, setSummaryJSON }: SectionComponentProps): JSX.Element {
    const track = useExperienceTracking();

    // Convert attendees array to comma-separated string for the text field
    const attendeesString = summaryJSON.attendees
        .filter((attendee) => attendee.toLowerCase() !== 'spinach.io')
        .filter((attendee) => attendee.toLowerCase() !== 'spinach ai')
        .join(', ');

    const handleAttendeesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Edit Attendees',
        });

        // Split by comma, trim whitespace, and filter out empty strings
        const newAttendees = event.target.value
            .split(',')
            .map((name) => name.trim())
            .filter((name) => name !== '');

        const updatedJson = {
            ...summaryJSON,
            attendees: newAttendees,
        };
        setSummaryJSON(updatedJson);
    };

    return (
        <>
            <Row style={{ marginTop: '10px' }}>
                <HeaderThree>Attendees</HeaderThree>
            </Row>
            <Row>
                <SummaryTextField
                    id="attendees-input"
                    placeholder="Add attendee names separated by commas"
                    value={attendeesString}
                    onChange={handleAttendeesChange}
                />
            </Row>
        </>
    );
}
